.y-axis-oos path {
  stroke: #bfbfbf;
  stroke-width: 3;
  shape-rendering: crispedges;
}

.x-axis-oos text {
  font-size: 14px;
  font-weight: bold;
  fill: #333;
}

.x-axis-oos path {
  color: #bfbfbf;
}

.vertical-line-container line:last-child {
  stroke-width: 0;
}
