.edit-img{
    cursor: pointer;
    float: right;
    left: -98px !important;
    position: relative !important;
    top: 11.8%;
    width: 11%;
    opacity: 0;
}

.edit-spn{
    cursor: pointer;position: relative;height: 22px;text-align: left;font-size: 14px;letter-spacing: 0.9px;color: #000000;opacity: 1;left: 49.5%;font-weight: 600;top: 15%;
}