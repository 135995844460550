.rotation-list .breadcrumb {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  /* background: linear-gradient(to bottom, #f1f1f1,#fff); */
  background: #f1f1f1;
  border-radius: 0;
  color: #666;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 7px;
  padding: 8px 15px;
}

.rotation-list .breadcrumb > li {
  display: inline-block;
  color: #666;
}

.rotation-list-container {
  /* width: 100%; */
  padding: 0 5px;
  overflow: auto;
}

.rotation-list-container .list-group {
  margin-bottom: 6px !important;
}

.rotation-list-container .dark-background a {
  color: #666;
  /* background-color: #F3F4F5; */
  text-decoration: none;
}

.rotation-list-container .dark-background a:hover,
a:focus {
  text-decoration: underline;
}

.rotation-list-container .dark-background:hover {
  background-color: #f1f1f1;
}

.rotation-page-list-container .breadcrumb {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  /* background: linear-gradient(to bottom, #f1f1f1,#fff); */
  background: #f1f1f1;
  border-radius: 0;
  color: #666;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 7px;
  padding: 8px 15px;
}

.dark .breadcrumb {
  background: #333 !important;
  border-color: rgb(63, 63, 63) !important;
  border-style: solid;
  border-width: 1px;
  color: rgb(216, 216, 216) !important;
  margin-bottom: 5px !important;
}

.dark .breadcrumb li {
  color: rgb(216, 216, 216) !important;
}

.rotation-page-list-container .breadcrumb > li + li:before {
  /* padding: 0 5px; */
  color: #666;
  content: "/\00a0";
  padding-left: 6px;
  padding-right: 2px;
}

.rotation-page-list-container .breadcrumb li a {
  color: #666;
  text-decoration: none;
}

.rotation-page-list-container .breadcrumb li a:hover {
  text-decoration: underline;
}

.rotation-page-list-container .breadcrumb li {
  color: #666;
}

.rotation-page-item-container {
  padding: 0 5px;
  overflow: auto;
}

.rotation-page-item-container .table {
  margin-bottom: 6px;
}

.rotation-page-item-container .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.rotation-page-item-container .table > tbody > tr > td {
  border-bottom-width: 0px;
}

.action-col {
  /* width: 30px; */
  text-align: center;
  vertical-align: center;
}

.dark .dark-background {
  background-color: rgb(38, 38, 38) !important;
  border: 1px solid rgb(63, 63, 63) !important;
}

.dark .dark-background {
  color: rgb(216, 216, 216) !important;
}

.dark .dark-background a {
  color: rgb(216, 216, 216) !important;
}

.dark .button-theme-dark {
  background: black !important;
  border-color: rgb(63, 63, 63) !important;
}

/* .dark .button-theme-dark .dx-icon {
  color: #dbdbdb !important;
} */

.dark .breadcrumb li a {
  color: rgb(216, 216, 216) !important;
}

.dark .rotation-page-item-container .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  color: rgb(216, 216, 216) !important;
  background: rgb(38, 38, 38) !important;
}

.dark .rotation-page-item-container .table > tbody > tr > td {
  background: rgb(38, 38, 38) !important;
  color: rgb(216, 216, 216) !important;
}

.dark .rotation-page-item-container .table > thead > tr > th {
  border-bottom: 2px solid rgb(63, 63, 63) !important;
}

.dark .icons_cls {
  color: #dbdbdb !important;
}

.dark .button-dark1 {
  background: black !important;
  border-color: rgb(63, 63, 63) !important;
  color: #dbdbdb;
}

/* .dark .configurator .table > tbody > tr > td {
  background: rgb(38, 38, 38) !important;
  color: rgb(216, 216, 216) !important;
  color: #444;
}

.dark .configurator .table > thead > tr > th {
  border-bottom: 2px solid rgb(63, 63, 63) !important;
  background: rgb(38, 38, 38) !important;
  color: rgb(216, 216, 216) !important;
} */
