.recycle-bin .breadcrumb {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  background: #f1f1f1;
  border-radius: 0;
  color: #666;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 7px;
  padding: 8px 15px;
}

.recycle-bin .breadcrumb li {
  color: #666;
}

.recycle-bin .breadcrumb li a {
  color: #666;
}

.recycle-bin .list-group {
  margin-bottom: 6px !important;
  margin-top: 6px !important;
}

.recycle-bin .list-group-item {
  padding: 10px 15px !important;
}

.recycle-bin .main-container {
  padding: 0 5px;
  overflow: auto;
}

.recycle-bin .dark-background:hover {
  background-color: #f1f1f1;
}

.recycle-bin .dark-background {
  color: #666;
}

.dark .recycle-bin .dark-background {
  color: rgb(216, 216, 216) !important;
}

.dark .recycle-bin .dark-background:hover {
  background-color: #333 !important;
}

.dark .recycle-bin .dark-background {
  color: rgb(216, 216, 216) !important;
}

.dark .recycle-bin .list-group-item {
  margin-bottom: -1px !important;
}

.recycle-bin .breadcrumb > li + li:before {
  color: #666;
  content: "/\00a0";
  padding-left: 6px;
  padding-right: 2px;
}

.recycle-bin .breadcrumb li a {
  color: #666;
  text-decoration: none;
}

.recycle-bin .breadcrumb li a:hover {
  text-decoration: underline;
}

.recycle-bin .wrap-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recycle-bin .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  color: #444;
}

.recycle-bin .table > tbody > tr > td {
  border-bottom-width: 0px;
}

.dark .recycle-bin .table > tbody > tr > td {
  background: rgb(38, 38, 38) !important;
  color: rgb(216, 216, 216) !important;
  color: #444;
}

.dark .recycle-bin .table > thead > tr > th {
  border-bottom: 2px solid rgb(63, 63, 63) !important;
  background: rgb(38, 38, 38) !important;
  color: rgb(216, 216, 216) !important;
}

.recycle-bin .table {
  color: #444;
}

.recycle-bin .action-col {
  width: 60px !important;
  text-align: center;
}

.recycle-bin .wrap-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
