
.page-header {
    border-bottom: 3px solid #cfcfcf;
    height: 68px;
    left: 10px;
    top: 20px;
}

.page-header-dark {
	border-bottom: 3px solid rgb(15,15,15);
	height: 68px;
	left: 10px;
	top: 20px;
	background: rgb(38,38,38);
	color: rgb(216,216,216);
}

.logo {
    position: absolute;
    top: 4px;
}

.toolbar {
    position: absolute;
    top: 10px;
    left: calc(50% - 81px);
}

.toolbar-dark {
  position: absolute;
  top: 10px;
  left: calc(50% - 81px);
  background: #333;
}

.toolbar-dark .button-dark {
  background: black !important;
  border-color: rgb(63,63,63) !important;
}

.pagerotate .dropdown-menu {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    display: none;
    float: left;
    font-size: 14px;
    left: 40px;
    list-style: outside none none;
    min-width: 62px;
    padding: 5px 0;
    position: absolute;
    top: 100%;
    z-index: 1000;
}

.dx-icon-PageIcon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAELSURBVEhL7ZUhDoQwEEW5NAJDAoILYBBYBBaBQCCwCAwCgeECCCxilt9kdttNG1qyJCsQL5kZ+uczJbQeEb3Z953quqYwDMn3fSvSNKV5ng/5p4+MkgzDQHmeC4Et0CRJcsjVxoySlGVJQK6dsa6rmOS7ziiJbDBNE2VZZqSqKrGODYIgEFrkqDNGA3wP3ZYw3IgNUIOWjRmjgesEiJumEc8QM0YD1wkQOxk8EzBGg9sn6PteCE1wo8sGtjwTGPnPCdq2vWSAkxTxqcG2beLykLfChqIoDrmFAbMsC0VRRF3XaX8yGbwU66wNwNldqwMGPA2jLJAZx9Hp8gdxHBPOMLmP0vQOtMXfQd4LS16+UcFn18oAAAAASUVORK5CYII=) 0 0 no-repeat;
  }

.icon-clockIcon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAABOFBMVEUAAABqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlqcXlaoAFKAAAAZ3RSTlMAAQIDBAUGBwgJCgsPEBEUFx4gISIjKC0vMTIzNTY3OjtAQkNERUpNT1BhYmNrbW9xd3h7fH6Cg4mPkZKXmJqbnqClpqqtr7C0t7m6vsDBw8XHyMrOz9HT19ne4OLk6Ovt7/Hz9fn9Rssy2gAAAaJJREFUOMudlGdbwjAQx691gCLDrbhAxQGighsH4BbcCiq4QGi//zewvTRtUhIfHv9vern8OJLcAOAUjG4WXn++S2fJyV6Qyhuv6Ixu5lQh5klruktfS0orN1PXBXoOuTBlT5dokeM6r5ydz8ujfLHhrFMMpxaoNx/uJi5f7I761hwwa7myAfZvhp8s9wL1xMi6EXYfPEE2NOv3viYuP5xwapf1LBPkxR7Ikly47re5Hk0vWeY0iRkx7QCxR5g8GktqJ3GzYoZMobkDYlAp4/aoYWFGNK8EhDEEDwD60dgGGQhVDKTCMoKDcjCOQAj28avKwQEEonBvfl5ADnoQTMC7+Tn9A1QQTEPTuhSqI2gqwoGA4C4f8dgurtaI3BmvBSA9I3frdcqdtN5a8I686DsKMsOLZkaQa052riHdUj1cldvVI6hHVkw9QgbtWp+IYytc0DO2+J6Rd2Gc70Kmr/0sN/To7mtmUuTG6aSYF00KfvZcHOYKktljHCfT3jQzFGlvPprltNHexDVHyUqVxW5jqrxUgrNbxbdGrXy+OuWD/+kXfjwBs7pYHyYAAAAASUVORK5CYII=) 0 0 no-repeat;
  }

  .icon-themeIcon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURXV1dXl5eX19fYGBgZGRkaGhoaqqqra2tsLCwsrKytbW1t7e3ubm5u7u7vr6+v///82pi4wAAAAQdFJOU////////////////////wDgI10ZAAAACXBIWXMAAB2HAAAdhwGP5fFlAAAAhklEQVQoz23QsRGCQBBA0Q84Qw9EFoChIeOVQhtYBDXQwdmBJZBoAfSgjARwDuy6R+Bmb/5ssEvYTcR1hzHxETWFYYTEYwEuWCDtiaHUHQkKCQINAg0bPho2zE7Cirl5SFjRUblST1iO5M9eMcHBjntBbhjgZLiR3X9YXNbaQ95n/+c7IYQvWe3YnftWnKUAAAAASUVORK5CYII=) 0 0 no-repeat;
  }
  
  .icon-fullscreenIcon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURXV1dXl5eX19fYWFhYmJiZmZmZ2dnaqqqra2tsrKytra2t7e3ubm5vLy8vr6+v///8ibmdAAAAAQdFJOU////////////////////wDgI10ZAAAACXBIWXMAAB2HAAAdhwGP5fFlAAAAs0lEQVQoz53OMQrCQBAF0K9YxCrb2HuAiCfwAIKWW3sCYT2B4AXEE0jAPsewDCLY5gpKUDFGv/sTIdYuLMxjZvcP+HNwtXau4mTtDGe0EqHsI/To1jObCu1UdWGMRw+REGMV4pI4tQoTlEMUvGNAHrBmqhy3JR+Bcvx96k36RbPBn9hl5GsivH34iDxqDdyy2If7NSIukY+Nwuna+xA51FDLCEH91QJCJ6vgKmBaj3k0keQHOhbZ3xE+HooAAAAASUVORK5CYII=) 0 0 no-repeat;
  }
  
  .icon-exitFullscreenIcon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURXV1dXl5eX19fZ2dnaqqqrKysrq6ur6+vsrKytra2t7e3uLi4ubm5vLy8vr6+v////aQQEMAAAAQdFJOU////////////////////wDgI10ZAAAACXBIWXMAAB2HAAAdhwGP5fFlAAAAsklEQVQoz43PMQrCQBAF0L8QRK201GpvYmNhlysEhHSSK9gqgliKRayCBDyENt7AK1imMkRZ+M64RoKVUyz7YGd2PtgocFFfL0RlUn93NkGJAXfkgWeEYGS2Q3K/soH0lOh3BQahgBEUCHQaNx5txaPngbXgFscz8qrnzwZ/4pnnxy8qfEYn+mzu0Xr3nDw6Cmc9JBckx0QRSy44ybEkM8mV4i45pArJNQLH9ZfTorEB+QLqfdLrBH+UTgAAAABJRU5ErkJggg==) 0 0 no-repeat;
  }

  .icon-fullscreenIcon-dark {
    background: url(../../../assets/enter-full-screen-icon-dark.png) 0 0 no-repeat;
}

.dx-icon-exitFullscreenIcon-dark {
    background: url(../../../assets/exit-full-screen-icon-dark.png) 0 0 no-repeat;
}

.icon-themeIcon-dark {
    background: url(../../../assets/theme-icon-dark.png) 0 0 no-repeat;
}

.dx-icon-PageIcon-dark {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAECSURBVEhL7ZUhDoQwFERRKBQKxQFQKBQKxQFQeDRBoVAoFIozoFAoMBztb6Zpk5K00LLJZrPZSSYNhf9fpwTqyHJdl6qqonVd6TgOI0/TRHEcE29xrSzLaBgGVmBq1CzLQlgcb6NX27YE80sjBUHAkmDkU3rJgCRJaBxHrbuuY88JwL7vrPYSJAMQWbUlwmEYngDYKry/pmnMALYJMOZ5zu5hXql3ElgD/gmU+mgCNEGhzqLRY4Cp/gm0/s4EZVk+AmzbRp7n3QN836d5nk9bYeK6rlnTW4BQFEXslCqKQvmRycaieJk5ALI6a7kA6PverCZNU6vDH0Zq/MN4i5+Q47wAJ/qVgfYHJdAAAAAASUVORK5CYII=) 0 0 no-repeat;
}

.icon-clockIcon-dark {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAABOFBMVEUAAACmqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq2mqq0gnq22AAAAZ3RSTlMAAQIDBAUGBwgJCgsPEBEUFx4gISIjKC0vMTIzNTY3OjtAQkNERUpNT1BhYmNrbW9xd3h7fH6Cg4mPkZKXmJqbnqClpqqtr7C0t7m6vsDBw8XHyMrOz9HT19ne4OLk6Ovt7/Hz9fn9Rssy2gAAAaJJREFUOMudlGdbwjAQx691gCLDrbhAxQGighsH4BbcCiq4QGi//zewvTRtUhIfHv9vern8OJLcAOAUjG4WXn++S2fJyV6Qyhuv6Ixu5lQh5klruktfS0orN1PXBXoOuTBlT5dokeM6r5ydz8ujfLHhrFMMpxaoNx/uJi5f7I761hwwa7myAfZvhp8s9wL1xMi6EXYfPEE2NOv3viYuP5xwapf1LBPkxR7Ikly47re5Hk0vWeY0iRkx7QCxR5g8GktqJ3GzYoZMobkDYlAp4/aoYWFGNK8EhDEEDwD60dgGGQhVDKTCMoKDcjCOQAj28avKwQEEonBvfl5ADnoQTMC7+Tn9A1QQTEPTuhSqI2gqwoGA4C4f8dgurtaI3BmvBSA9I3frdcqdtN5a8I686DsKMsOLZkaQa052riHdUj1cldvVI6hHVkw9QgbtWp+IYytc0DO2+J6Rd2Gc70Kmr/0sN/To7mtmUuTG6aSYF00KfvZcHOYKktljHCfT3jQzFGlvPprltNHexDVHyUqVxW5jqrxUgrNbxbdGrXy+OuWD/+kXfjwBs7pYHyYAAAAASUVORK5CYII=) 0 0 no-repeat;
}