.tbl-datagrid {
    color: #333333;
  }

  .tbl-datagrid {
    position: relative;
    cursor: default;
    white-space: normal;
    line-height: normal;
  }


  .tbl-datagrid {
    font-family: Calibri, Segoe, "Segoe UI", Candara, Optima, Arial, sans-serif;
    font-size: 14px;
    /* padding: 0px 5px 0px 0px; */
    
  }


  .tbl-datagrid-rowsview {
    position: relative;
    overflow: hidden;
  }

  .tbl-datagrid-nowrap {
    white-space: nowrap;
  }

  .tbl-datagrid-rowsview .tbl-datagrid-content {
    overflow-anchor: none;
  }

  .tbl-datagrid-content {
    position: relative;
  }

  .tbl-datagrid-content .tbl-datagrid-table.tbl-datagrid-table-fixed {
    max-width: none;
  }

  .tbl-datagrid-content .tbl-datagrid-table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    max-width: 10px;
  }

  .tbl-datagrid-table {
    background-color: transparent;
  }

  .tbl-datagrid-table-fixed {
    table-layout: fixed;
    width: 100%;
  }

  .tbl-datagrid .tbl-column-lines > th {
    border-left: 1px solid #d2d2d2;
    /* border-right: 1px solid #d2d2d2; */
  }

  .tbl-col-headers .tbl-header-row > th {
    white-space: nowrap;
    overflow: hidden;
  }

  .tbl-col-headers .tbl-first-row > th {
    border-bottom: 1px solid #d2d2d2;
    background-color: #ffffff;
    color: #767676;
    font-weight: normal;
    -ms-touch-action: pinch-zoom;
    touch-action: pinch-zoom;
    padding: 4px;
    background-clip: padding-box;
    -webkit-backface-visibility: visible;
  }

  .tbl-datagrid-action {
    cursor: pointer;
  }

  .tbl-datagrid .tbl-column-indicators {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
  }

  .tbl-col-headers .tbl-header-row > th > .tbl-datagrid-text-content {
    white-space: nowrap;
    vertical-align: top;
  }

  .tbl-datagrid-text-content {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  .tbl-datagrid-rowsview .tbl-row:nth-child(2n+1) {
    background-color: #f6f6f6;
  }

  .tbl-row {
    height: 24px;
  }

  .tbl-datagrid-content .tbl-datagrid-table .tbl-row > td:first-child {
    border-left: 0px;
  }

  .tbl-datagrid-content .tbl-datagrid-table .tbl-row > td {
    vertical-align: top;
  }

  .tbl-datagrid-rowsview .tbl-row > td {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  .tbl-datagrid .tbl-row > td {
    padding: 4px;
  }

  .tbl-datagrid .tbl-column-lines > td {
    border-left: 1px solid #d2d2d2;
    /* border-right: 1px solid #d2d2d2; */
  }

  .menu-ddl > li > a {
    padding: 3px 5px !important;
    cursor: pointer !important;
}

.sorting-icon {
  color: #898989;
}

.menu-item-text {
  padding: 0 25px 5px 23px;
}

.dark-datagrid .menu-item-text {
  color: #ffffff;
}

.menu-ddl > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333;
  white-space: nowrap;
  font-size: 14px;
  text-decoration: none;
}

.menu-ddl > li > a:hover, .menu-ddl > li > a:focus {
  background-color: rgba(175,175,175,.15);
  color: #818181;
  text-decoration: none;
}

.dark-datagrid .menu-ddl{
  background-color: rgb(38, 38, 38);
  color: rgb(216, 216, 216);
  border: 1px solid #ddd;
}

.dark-datagrid .tbl-widget {
  color: #dbdbdb;
}

.dark-datagrid {
  color: #b5b5b5 !important;
}

.dark-datagrid .tbl-col-headers .tbl-first-row > th {
  border-bottom: 1px solid #4c4c4c;
  background-color: #262626 !important;
}

.dark-datagrid .tbl-column-lines > th {
  border-left: 1px solid #4c4c4c !important;
}

.dark-datagrid .tbl-datagrid-text-content {
  background-color: rgb(38, 38, 38) !important;
  color: rgb(216,216,216) !important;
}

.dark-datagrid .tbl-datagrid-rowsview .tbl-row:nth-child(2n+1) {
  background-color: #333333;
}

.dark-datagrid .tbl-column-lines > td {
  border-left: 1px solid #4c4c4c !important;
}