.disabled i {
    color: lightgray !important;
  }

  .box {
    height: 100%;
    border: 1px solid #ccc;
    cursor: move;
    background-color: "rgb(196, 214, 160)";
    width: "190px"
    /* background-color: #fff; */
  }


  .box-header {
    /* background-color: #eee;
    padding: 0 30px 0 10px;
    border-bottom: 1px solid #ccc;
    cursor: move; */
    position: relative;
  }

  .box-shadow-enabled{
    box-shadow: "3px 3px 5px rgba(0, 0, 0, 0.3)";
  }


  .box-header-btns {
    top: 5px;
    right: 0px;
    cursor: pointer;
    position: absolute;
  }


  .pull-right {
    float: right !important;
  }

  .close {
    color: #333;
    float: right;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    opacity: 0.4;
    text-shadow: 0 1px 0 #fff;
    background: none;
    border: none;
    cursor: pointer;
    padding-right: 5px;
  }