.pageContainer{
    text-align: center; 
    width: 100%; 
    height: 100%;
}

.pageNavigator{
    width:100%; 
    float: left; 
    top:42%; 
    position: absolute; 
    /* padding:5px; */
}

.leftNavigator{
    float:left;
    position:relative;
}

.leftNavigator i{
    color: darkgray;
    cursor: pointer;
    font-size: 4em;
    left: 2%;
    position: relative;
    top: 19px;
}

.rightNavigator{
    float:right;
    position:relative;
}

.rightNavigator i{
    color: darkgray;
    cursor: pointer;
    font-size: 4em;
    left: 2%;
    position: relative;
    top: 19px;
}

.page-list-content{
    text-align: center;
     width: 89%;
      height: 100%;
       left:49px; 
       position: relative;
       top:16px;
}

.disabled i {
    color: lightgray !important;
}   