.configurator .breadcrumb {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  background: #f1f1f1;
  border-radius: 0;
  color: #666;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 7px;
  padding: 8px 15px;
}

.configurator .breadcrumb li {
  color: #666;
}

.configurator .breadcrumb li a {
  color: #666;
}

.configurator .list-group {
  margin-bottom: 6px !important;
}

.configurator .list-group-item {
  padding: 10px 15px !important;
}

.configurator .main-container {
  padding: 0 5px;
  overflow: auto;
}

.configurator .dark-background:hover {
  background-color: #f1f1f1;
}

.configurator .dark-background {
  color: #666;
}

.dark .configurator .dark-background {
  color: rgb(216, 216, 216) !important;
}

.dark .configurator .dark-background:hover {
  background-color: #333 !important;
}

.dark .configurator .dark-background {
  color: rgb(216, 216, 216) !important;
}

.dark .configurator .list-group-item {
  margin-bottom: -1px !important;
}

.configurator .breadcrumb > li + li:before {
  color: #666;
  content: "/\00a0";
  padding-left: 6px;
  padding-right: 2px;
}

.configurator .breadcrumb li a {
  color: #666;
  text-decoration: none;
}

.configurator .breadcrumb li a:hover {
  text-decoration: underline;
}

.configurator .wrap-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dark .button-theme-dark {
  background: black !important;
  border-color: rgb(63, 63, 63) !important;
}

/* .dark .button-theme-dark .dx-icon {
  color: #dbdbdb !important;
} */

.configurator .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  color: #444;
}

.configurator .table > tbody > tr > td {
  border-bottom-width: 0px;
}

.dark .configurator .table > tbody > tr > td {
  background: rgb(38, 38, 38) !important;
  color: rgb(216, 216, 216) !important;
  color: #444;
}

.dark .configurator .table > thead > tr > th {
  border-bottom: 2px solid rgb(63, 63, 63) !important;
  background: rgb(38, 38, 38) !important;
  color: rgb(216, 216, 216) !important;
}

.configurator .table {
  color: #444;
}

.configurator .form-control {
  width: 100% !important;
  height: 26px !important;
  padding: 3px !important;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #f1f1f1;
}

.dark .table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: rgb(63, 63, 63) !important;
}

.configurator .action-col {
  width: 60px !important;
  text-align: center;
}
