.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dx-button {
  background-image: linear-gradient(to top, #f1f1f1, #fff);
  border-color: #d2d2d2 #c9c8c8 #c3c3c2;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0px;

  display: inline-block;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
  outline: none;
}

.dx-button {
  padding: 5px !important;
}

.dx-button .dx-button-content {
  padding: 0px !important;
}

.dx-button {
  padding: 5px !important;
}

.dx-button .dx-icon {
  width: 24px;
  height: 24px;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px 24px;
  background-size: 24px 24px;
}

.dx-button .dx-icon {
  display: inline-block;
  vertical-align: middle;
}

.dx-button-has-icon:not(.dx-button-has-text) {
  padding: 5px;
}

.dx-button-has-text .dx-button-content {
  padding: 0px !important;
}

.pagerotate .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.settings input,
select {
  width: 300px;
  border: 1px solid #cfcfcf;
  /* height: 26px; */
  padding: 3px;
}

.dx-button-has-text {
  height: 25px;
  width: 65px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.settings .error {
  display: block;
  color: red;
}

.required-error {
  border: 1px solid #ff0000 !important;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  /* width: 20px; */
  width: 14px;
  height: 20px;
}

/* .react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
} */

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: -65px;
  width: 2px;
  height: 132px;
  /* border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4); */
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  /* background: black; */
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  /* transform: rotate(90deg); */
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  /* transform: rotate(180deg); */
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  /* transform: rotate(270deg); */
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  /* left: 0; */
  left: -7px;
  /* transform: rotate(135deg); */
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  /* right: 0; */
  right: -4px;
  /* transform: rotate(315deg); */
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  /* margin-left: -10px; */
  margin-left: 0px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  /* top: 0; */
  top: -6px;
  /* transform: rotate(225deg); */
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  /* bottom: 0; */
  bottom: -7px;
  /* transform: rotate(45deg); */
  transform: rotate(90deg);
}

.react-grid-item.react-grid-placeholder {
  background-color: rgb(196, 214, 160);
  opacity: 0.5;
}

hr.style-one {
  margin: 17px 0px 7px;
  border: 0;
  height: 1px;
  background: #333;
  background-image: -webkit-linear-gradient(left, #eee, #ccc, #eee);
  background-image: -moz-linear-gradient(left, #eee, #ccc, #eee);
  background-image: -ms-linear-gradient(left, #eee, #ccc, #eee);
  background-image: -o-linear-gradient(left, #eee, #ccc, #eee);
}

select option {
  padding: 3px;
}

.hide {
  transition: 0.01s;
  transform: translateX(-9999px);
}

.dark {
  background: rgb(38, 38, 38) !important;
}

.button-dark {
  background: black !important;
  border-color: rgb(63, 63, 63) !important;
  color: #dbdbdb;
}

.dark .scroller-bg {
  background: #dbdbdb !important;
}

.dark .tbl-sort {
  color: #b5b5b5 !important;
}

.dark .tbl-widget {
  color: #dbdbdb !important;
}

.dark .grid-loader-panel {
  background: rgb(38, 38, 38) !important;
}

.dark .settings-btn-dark {
  background: black !important;
  border-color: rgb(63, 63, 63) !important;
  color: #dbdbdb;
}

.dark .ddl-menu-dark {
  background: rgb(38, 38, 38) none repeat scroll 0 0 !important;
  margin-top: 1px;
}

.dark .ddl-menu-dark > li > a:hover,
.dark .ddl-menu-dark > li > a:focus {
  color: #abc43a !important;
  text-decoration: none;
  background-color: rgb(38, 38, 38) !important;
}

.dom-legend-item {
  display: inline-block;
  padding: 0 10px;
}

.dom-legend-box {
  width: 12px;
  height: 12px;
  display: inline-block;
  font-size: 10px;
  background: red;
  color: red;
}

.tooltip-OOS {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border: 1px solid gray;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
  font-size: 16px;
  font-family: Calibri, Segoe, "Segoe UI", Candara, Optima, Arial, sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
