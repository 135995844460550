

.container {
    overflow: hidden;
    position: absolute;
    width: calc(100% - 4px);
    left: 2px;
    bottom: 8px;
    top: 75px;
  }

  .container-dark {
    overflow: hidden;
    position: absolute;
    width: calc(100% - 4px);
    left: 2px;
    bottom: 8px;
    top: 75px;
    background: rgb(38,38,38);
  }