.item_list li {
    border: 1px solid gray;
    padding: 10px;
    cursor: pointer;
    margin: 3px;
    width: 313px;
    color: black;
    list-style-type: none;
    background-color: silver;
}

.item_list{
    padding-left: 0;
    margin-left: -2px;

}

.myHandle {
    height: 2px;
    width: 15px;
    top: 0.5px;
    float: left;
    margin-right: 20px;
    cursor: move !important;
}


.hide-li {
    opacity: 0.5;
}