  .save-button {
    background-image: linear-gradient(to top, #f1f1f1, #fff);
    border-color: #d2d2d2 #c9c8c8 #c3c3c2;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    max-width: 100%;
    outline: none;
    padding: 8px;
    /* height: 37px; */
    width: 50px;
    user-select: none;
  }

  .save-button .save-button-content {
    padding: 0px !important;
  }

  .save-button-text {
    /* display: inline;
    vertical-align: middle; */
  }


  .widget-button {
    background-image: linear-gradient(to top, #f1f1f1, #fff);
    border-color: #d2d2d2 #c9c8c8 #c3c3c2;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    max-width: 100%;
    outline: none;
    padding: 8px;
    /* height: 37px; */
    width: 50px;
    user-select: none;
  }


  .widget-button .widget-button-content {
    padding: 0px !important;
  }

  .widget-button-text {
    /* display: inline;
    vertical-align: middle; */
  }

