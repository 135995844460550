.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color:  rgba(0, 0, 0, 0.4);
  }
  
  .header {
    width: 100%;
    height: 32px;
    color: #666666;
    border-bottom: 1px solid #cfcfcf;
    background-color: rgb(252, 252, 252);
  }

  .header-dark {
    width: 100%;
    height: 32px;
    color: rgb(216,216,216);
    border-bottom: 1px solid black;
    background-color: rgb(15,15,15);
}

.header-dark .title {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  height: 30px !important;
  overflow: hidden;
  right: 80px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  left: 7px;
  top: 7px;
  color: rgb(216,216,216);
}

  .header .title {
    color: #666666;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    height: 30px !important;
    overflow: hidden;
    right: 80px;
    white-space: nowrap;
    font-size: 15px;
    font-weight: bold;
    position: absolute;
    left: 7px;
    top: 7px;
  }

  .modal {
    position: fixed;
    top: 20vh;
    left: 5%;
    width: 90%;
    background-color: white;
    padding: 1rem;
    /* border-radius: 14px; */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
  }

  /* .dialog-theme-custom {
    padding-bottom: 160px;
    padding-top: 160px;
  } */


  .dialog-content {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 400px;
    border: 1px solid #cfcfcf; -webkit-box-shadow: 0px 0px 2px 0px #cfcfcf; -moz-box-shadow: 0px 0px 3px 0px #cfcfcf; box-shadow: 0px 0px 3px 0px #cfcfcf;
  }

 
  .dialogContent {
    padding: 10px 20px 20px 20px;
    background: #fff;
    color: #444;
  }

  .dialogContent-dark {
    padding: 10px 20px 20px 20px;
    background: rgb(38,38,38);
    color: rgb(216,216,216);
  }

  .dialog-theme-custom {
    padding-bottom: 160px;
    padding-top: 160px;
  }

  .dialog {
    position: fixed;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 30;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .dialog-content p {
    margin: 8px 0px 3px 0px;
  }
  
  .dialog.dialog-theme-custom .dialog-close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
}

.dialog.dialog-theme-custom .dialog-close:before {
  background: transparent;
  color: #bbb;
  content: "\00D7";
  font-size: 26px;
  font-weight: 400;
  height: 30px;
  line-height: 26px;
  position: absolute;
  right: 3px;
  text-align: center;
  top: 3px;
  width: 30px;
}

.dialog-close:before {
  font-family: 'Helvetica', Arial, sans-serif;
  cursor: pointer;
}

.dialog.dialog-theme-custom .dialog-close:hover:before, .dialog.dialog-theme-custom .dialog-close:active:before {
  color: #777;
}

  /* @media (min-width: 768px) {
    .modal {
      width: 40rem;
      left: calc(50% - 20rem);
    }
  } */
  
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }