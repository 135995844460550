.clone-img{
    cursor: pointer;
    float: right;
    left: -64px !important;
    position: relative !important;
    top: 46.7%;
    width: 10.2%;
    opacity: 0;
}

.clone-spn{
    cursor: pointer;
    position: relative !important;
    height: 22px;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.9px;
    color: #000000;
    opacity: 1;
    left: 131.5px !important;
    font-weight: 600;
    top: 51%;
}