.twitter-article {
    width: calc(100% - 15px);
    border-bottom: 1px solid #cfcfcf;
    float: left;
    margin-left: 8px;
    padding: 8px 0px 8px 0px;
    position: relative;
}

.twitter-pic {
    position: absolute;
}

.twitter-pic {
    position:absolute;
}
  
.twitter-pic img {
    float:left;
    border-radius:7px; 
    border:none;
      
}

.twitter-text {
    width: 100%;
    float: left;
    font-size: 14px;
    padding-left: 52px;
    padding-right: 12px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


.tweet-time {
    font-size: 12px;
    color: #878787;
    float: right;
}


.twitter-text p {
    margin:0px;
     
}
.twitter-text a,  h1 a {
    color: #444;
    text-decoration: none;
}
.twitter-text a:hover,  h1 a:hover {
    text-decoration: underline;
    color: #00acee;
}
  
.tweet-time {
    font-size:12px;
    color:#878787;
    float:right;
}
.tweet-time a, .tweet-time a:hover {
    color:#878787;
}



.intent {
    width: 25px;
    height: 16px;
    float: left;
}

.intent a{
    width:25px;
    height:16px;
    display:block;
    background-image:url(../../../../assets/tweet-actions.png);
    float:left;
}

.intent a:hover{
    background-position:-25px 0px;
}
  
#intent-retweet a{
    background-position:0px -17px;
}
#intent-retweet a:hover{
    background-position:-25px -17px;
}
#intent-fave a{
    background-position:0px -36px;
}
#intent-fave a:hover{
    background-position:-25px -36px;
}

#twitter-actions {
    width:75px;
    float:right;
    margin-right:5px;  
    margin-top:3px;
        display:none;
}

#retweet-indicator {
    width:14px;
    height:10px;
    background-image:url(../../../../assets/tweet-actions.png);
    background-position:-5px -54px;
    margin-top:3px;
    float:left;
}

#twitter-feed {
    width:258px;
    margin:auto;
    font-family: Arial, Helvetica, sans-serif;
    margin-top:60px;
    padding:8px 10px 5px 10px;
    border-radius:12px;
    background-color:#FFF;
    color:#333;
    overflow:auto;
}
  
#twitter-feed h1 {
    color:#5F5F5F;
    margin:0px;
    padding:9px 0px 9px 0px;
    font-size:18px;
    font-weight:lighter;   
}