.barlinechartWidget .barLegendBox {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 50%;
    justify-content: flex-start;
}

.barlinechartWidget .legendBoxContent {
    display: flex;
    align-items: center;
    /* flex-basis: 30%; */
    padding-bottom: 8px;
    margin-right: 10px;
}