.top-bar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 4px;
    background-color: #abc43a;
}

.show-header:before {
    position: absolute;
    top: -3px;
    left: 50%;
    width: 24px;
    margin-left: -17px;
    height: 15px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    content: "";
    font-family: FontAwesome;
    text-align: center;
    vertical-align: top;
    color: white;
    background: #abc43a;
}

.hide-header:before {
    position: absolute;
    top: -3px;
    left: 50%;
    width: 24px;
    margin-left: -17px;
    height: 15px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    content: "";
    font-family: FontAwesome;
    text-align: center;
    vertical-align: top;
    color: white;
    background: #abc43a;
}

.progress {
    background-color: transparent;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    height: 4px;
    margin-bottom: 20px;
    overflow: hidden;
}

.progress-bar {
    /*background-color: #428bca;*/
    background-color: transparent;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
    color: #fff;
    float: left;
    font-size: 12px;
    height: 100%;
    line-height: 20px;
    text-align: right;
    transition: width 0.6s ease 0s;
    width: 0;
}