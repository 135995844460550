.svg-font-color svg > path {
  fill: var(--ifm-font-color-base);
}

.queryBuilder {
  min-width: 420px;
}

.validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid {
  background-color: rgba(102, 51, 153, 0.4);
}
.validateQuery
  .queryBuilder
  .ruleGroup.queryBuilder-invalid
  .ruleGroup-addRule {
  font-weight: bold !important;
}
.validateQuery
  .queryBuilder
  .ruleGroup.queryBuilder-invalid
  > .ruleGroup-header::after {
  content: "Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.";
  color: white;
}
/* .validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value {
    background-color: rgba(102, 51, 153, 0.4);
    border-color: red;
  } */
.validateQuery
  .queryBuilder
  .rule.queryBuilder-invalid
  .rule-value::placeholder {
  color: #47246b;
}

html[data-theme="dark"]
  .validateQuery
  .queryBuilder
  .rule.queryBuilder-invalid
  .rule-value::placeholder {
  color: #945ec9;
}

.justifiedLayout
  .queryBuilder
  .ruleGroup-addGroup
  + button.ruleGroup-cloneGroup,
.justifiedLayout .queryBuilder .ruleGroup-addGroup + button.ruleGroup-lock,
.justifiedLayout .queryBuilder .ruleGroup-addGroup + button.ruleGroup-remove,
.justifiedLayout .queryBuilder .rule-operators + button.rule-cloneRule,
.justifiedLayout .queryBuilder .rule-operators + button.rule-lock,
.justifiedLayout .queryBuilder .rule-operators + button.rule-remove,
.justifiedLayout .queryBuilder .rule-value + button.rule-cloneRule,
.justifiedLayout .queryBuilder .rule-value + button.rule-lock,
.justifiedLayout .queryBuilder .rule-value + button.rule-remove,
.justifiedLayout .queryBuilder .control + button.rule-cloneRule,
.justifiedLayout .queryBuilder .control + button.rule-lock,
.justifiedLayout .queryBuilder .control + button.rule-remove,
.justifiedLayout .queryBuilder .chakra-select__wrapper + button.rule-cloneRule,
.justifiedLayout .queryBuilder .chakra-select__wrapper + button.rule-lock,
.justifiedLayout .queryBuilder .chakra-select__wrapper + button.rule-remove {
  margin-left: auto;
}

.custom-style-query-builder .ruleGroup-combinators.form-select.form-select-sm {
  width: 32px;
}

.custom-style-query-builder .ruleGroup-addRule {
  margin-left: auto;
}

/* .custom-style-query-builder .rule-operators.form-select.form-select-sm {
  width: 85px;
}

.custom-style-query-builder .rule-fields.form-select.form-select-sm {
  width: 170px;
} */

.custom-style-query-builder .rule-value.form-control.form-control-sm {
  min-height: auto;
  width: auto;
}

/* .custom-style-query-builder.react-datepicker-ignore-onclickoutside {
  height: 25px;
} */

.custom-style-query-builder .custom-datepicker {
  height: 27px;
  border: 1px solid #ced4da;
  width: 186px;
  padding: 0px 5px;
}

.custom-style-query-builder .rule.queryBuilder-invalid {
  background-color: #fdd;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #f99;
}

.ruleGroup {
  border: 1px solid #eee;
  background: rgba(255, 255, 255, 0.9) !important;
}

.dark .ruleGroup {
  border: 1px solid #eee;
  background: transparent !important;
}

.queryBuilder-branches .rule::before,
.queryBuilder-branches .rule::after,
.queryBuilder-branches .ruleGroup .ruleGroup::before,
.queryBuilder-branches .ruleGroup .ruleGroup::after {
  border-color: #ccc !important;
}

.queryBuilder-branches .rule::before,
.queryBuilder-branches .ruleGroup .ruleGroup::before {
  border-width: 0 0 2px 2px !important;
}

.queryBuilder-branches .rule::after,
.queryBuilder-branches .ruleGroup .ruleGroup::after {
  border-width: 0 0 0 2px !important;
}

.add-rule-btn {
  color: #fff !important;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
  padding: 1px 5px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.add-rule-btn:hover,
.add-rule-btn:focus,
.add-rule-btn:active,
.add-rule-btn.active {
  color: #fff;
  background-color: #47a447 !important;
  border-color: #398439 !important;
}

.add-rule-btn:active:focus,
.add-rule-btn:focus {
  box-shadow: none !important;
}

.add-group-btn {
  color: #fff !important;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
  padding: 1px 5px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.add-group-btn:hover,
.add-group-btn:focus,
.add-group-btn:active,
.add-group-btn.active {
  color: #fff;
  background-color: #47a447 !important;
  border-color: #398439 !important;
}

.add-group-btn:active:focus,
.add-group-btn:focus {
  box-shadow: none !important;
}

.ruleGroup-header {
  gap: 0rem !important;
}

.remove-rule-btn {
  padding: 1px 5px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
}

.remove-group-btn {
  padding: 1px 5px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
}

.remove-group-btn:active:focus,
.remove-group-btn:focus,
.remove-rule-btn:active:focus,
.remove-rule-btn:focus {
  box-shadow: none !important;
}
