.custom-width {
  /* width: 70px; */
}

.linkClass {
  color: #abc43a;
}

.linkClass:hover {
  color: #abc43a;
}
