.axis path {
    fill: none;
    shape-rendering: crispEdges;
}

.axis path {
    stroke: none;
}

.y.axis line-dark {
    stroke: #ddd;
}

.x.axis line {
    stroke: none;
}

.saleschart text {
    font-size: 13px;
    font-family: Calibri, Segoe, "Segoe UI", Candara, Optima, Arial, sans-serif;
}

.donut text {
    fill: #444;
}


.dom-legend-item {
    display: inline-block;
    padding: 0 10px;
}

.dom-legend-box {
    /*width: 10px;
			height: 10px;*/
    width: 12px;
    height: 12px;
    display: inline-block;
    font-size: 10px;
    background: red;
    color: red;
}

.dom-legend-label {
    font-size: 14px;
}


.dark .sales-activity-widget {
    width: 100%;
    height: 100%;
    background: #262626 !important;
    border: 1px solid #262626 !important;
}

.sales-activity-widget {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
}

.sales-activity-widget {
    font-family: Calibri, Segoe, "Segoe UI", Candara, Optima, Arial, sans-serif;
}

.sales-activity-widget svg {
    width: 100%;
}

.sales-activity-widget .x-axis text {
    font-size: 12px;
    fill: #000;
    text-anchor: end;
}

.dark .sales-activity-widget .x-axis text {
    font-size: 12px;
    fill: #ccc !important;
    text-anchor: end;
}

.sales-activity-widget .x-axis path {
    stroke: none;
}

.sales-activity-widget .y-axis text {
    font-size: 12px;
    fill: #000;
}

.dark .sales-activity-widget .y-axis text {
    font-size: 12px;
    fill: #ccc;
}

.sales-activity-widget .y-axis line {
    stroke: #ddd;
}

.dark .sales-activity-widget .y-axis line {
    stroke: #333;
}

.sales-activity-widget .y-axis path {
    stroke: none;
}

.dark .sales-activity-widget .shaded-area-graph path {
    fill: #627491;
    /*fill: #8dbac5;*/
    opacity: 0.8;
}

.sales-activity-widget .shaded-area-graph path {
    fill: #deebf7;
    opacity: 0.75;
}

.dark .sales-activity-widget .zone rect {
    fill: white;
    opacity: 0.1;
}

.sales-activity-widget .zone rect {
    fill: #262626;
    opacity: 0.1;
}

.sales-activity-widget .zone .zone-start {
    stroke: #C55156;
    stroke-dasharray: 6 3;
    stroke-width: 3px;
}

.sales-activity-widget .zone .zone-end {
    stroke: #C55156;
    stroke-dasharray: 6 3;
    stroke-width: 3px;
}

.sales-activity-widget .activity_count_marker_start_attempts circle {
    fill: #afabab;
    stroke: #ddd;
    stroke-width: 1.5px;
}

.sales-activity-widget .activity_count_marker_start_attempts text {
    fill: #ddd;
    font-size: 20px;
}

.sales-activity-widget .activity_count_marker_start_msi circle {
    fill: #70ad47;
    stroke: #ddd;
    stroke-width: 1.5px;
}

.sales-activity-widget .activity_count_marker_start_msi text {
    fill: #ddd;
    font-size: 20px;
}

.sales-activity-widget .activity_count_marker_end_attempts circle {
    fill: #afabab;
    stroke: #ddd;
    stroke-width: 1.5px;
}

.sales-activity-widget .activity_count_marker_end_attempts text {
    fill: #ddd;
    font-size: 20px;
}

.sales-activity-widget .activity_count_marker_end_msi circle {
    fill: #70ad47;
    stroke: #ddd;
    stroke-width: 1.5px;
}

.sales-activity-widget .activity_count_marker_end_msi text {
    fill: #ddd;
    font-size: 20px;
}

.sales-activity-widget .activity_count_marker_cur_attempts circle {
    fill: #afabab;
    stroke: #ddd;
    stroke-width: 1.5px;
}

.sales-activity-widget .activity_count_marker_cur_attempts text {
    fill: #ddd;
    font-size: 20px;
}

.sales-activity-widget .activity_count_marker_cur_msi circle {
    fill: #70ad47;
    stroke: #ddd;
    stroke-width: 1.5px;
}

.sales-activity-widget .activity_count_marker_cur_msi text {
    fill: #ddd;
    font-size: 20px;
}

.sales-activity-widget .zone_count_marker circle {
    fill: #70ad47;
    stroke: #ddd;
    stroke-width: 1.5px;
}

.sales-activity-widget .zone_count_marker text {
    fill: #ddd;
    font-size: 20px;
}

.sales-activity-widget .tokens .point {
    fill: #bf9000;
}

.sales-activity-widget .tokens line {
    stroke: #bf9000;
}

.sales-activity-widget .tokens .round {
    fill: none;
    stroke: #bf9000;
    stroke-width: 2px;
}

.sales-activity-widget .tokens text {
    fill: #535353;
    font-size: 12px;
}

.dark .sales-activity-widget .tokens text {
    fill: #eee;
    font-size: 12px;
}

.dark .sales-activity-widget .slots {
    fill: #eee;
    font-size: 12px;
}

.sales-activity-widget .slots {
    fill: #000;
    font-size: 12px;
}

.dark .sales-activity-widget .donut .cost {
    fill: #eee;
    font-size: 22px;
}

.sales-activity-widget .donut .cost {
    fill: #535353;
    font-size: 22px;
}


.dark .sales-activity-widget .donut .label {
    fill: #eee;
    font-size: 10px;
    font-weight: lighter;
}

.sales-activity-widget .donut .label {
    fill: #535353;
    font-size: 10px;
    font-weight: lighter;
}

.sales-activity-widget .donut .actual_circle {
    fill: #a70606;
    stroke: #eee;
    stroke-width: 1.5px;
}

.sales-activity-widget .donut .actual_text {
    fill: #eee;
    font-size: 10px;
    font-weight: lighter;
}

.sales-activity-widget .donut .time_marker {
    stroke: #4286f4;
    stroke-width: 3px;
    stroke-dasharray: 5px 2px;
}

.chart-legend {
    text-align: center;
    font-size: 12px;
    padding: 10px 0 20px 0;
}

.chart-legend .chart-legend-item {
    display: inline-block;
    padding-right: 20px;
}

.chart-legend .chart-legend-item .legend-box {
    width: 14px;
    height: 14px;
    display: inline-block;
}

.chart-legend .chart-legend-item .attempt {
    color: #afabab;
    background-color: #afabab;
    margin-right: 2.5px;
}

.chart-legend .chart-legend-item .msi {
    color: #70ad47;
    background-color: #70ad47;
    margin-right: 2.5px;
}

.chart-legend .chart-legend-item .legend-label {
    color: #000 !important;
}

.dark .chart-legend .chart-legend-item .legend-label {
    color: #eee !important;
}


.dark .donut .donut-circle {
    fill: #262626;
    opacity: 0.8;
}

.donut .donut-circle {
    fill: #ffffff;
    opacity: 0.8;
}

.dark .tokens .token-circle {
    fill: #262626;
    opacity: 0.8;
    stroke: none;
}

.tokens .token-circle {
    fill: #ffffff;
    opacity: 0.7;
    stroke: #bf9000;
}