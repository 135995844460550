.tbl-datagrid-bottom-load-panel {
    text-align: center;
    padding: 10px;
    border-top: 1px solid #ddd;
    position: relative;
    margin-top: -57px;
    background: white;
}

.tbl-loadindicator {
    width: 32px;
    height: 32px;
    display: inline-block;
    overflow: hidden;
    border: none;
    background-color: transparent;
}


.tbl-loadindicator-wrapper {
    width: 100%;
    height: 100%;
    font-size: 32px;
    margin: auto;
}

.tbl-loadindicator-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.tbl-loadindicator-icon {
    direction: ltr;
}

.tbl-loadindicator-icon {
    position: relative;
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
}


    .tbl-loadindicator-icon .tbl-loadindicator-segment7 {
        -webkit-transform: rotate(315deg) translate(0, -142%);
        -moz-transform: rotate(315deg) translate(0, -142%);
        -ms-transform: rotate(315deg) translate(0, -142%);
        -o-transform: rotate(315deg) translate(0, -142%);
        transform: rotate(315deg) translate(0, -142%);
        -webkit-animation-delay: -0.125s;
        -moz-animation-delay: -0.125s;
        -o-animation-delay: -0.125s;
        animation-delay: -0.125s;
    }

    .tbl-loadindicator-icon .tbl-loadindicator-segment {
        position: absolute;
        width: 19%;
        height: 30%;
        left: 44.5%;
        top: 37%;
        opacity: 0;
        background: #606060;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        -webkit-border-top-left-radius: 10%;
        -moz-border-top-left-radius: 10%;
        border-top-left-radius: 10%;
        -webkit-border-top-right-radius: 10%;
        -moz-border-top-right-radius: 10%;
        border-top-right-radius: 10%;
        -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        -webkit-animation: tbl-generic-loadindicator-opacity 1s linear infinite;
        -moz-animation: tbl-generic-loadindicator-opacity 1s linear infinite;
        -o-animation: tbl-generic-loadindicator-opacity 1s linear infinite;
        animation: tbl-generic-loadindicator-opacity 1s linear infinite;
    }




    .tbl-loadindicator-icon .tbl-loadindicator-segment6 {
        -webkit-transform: rotate(270deg) translate(0, -142%);
        -moz-transform: rotate(270deg) translate(0, -142%);
        -ms-transform: rotate(270deg) translate(0, -142%);
        -o-transform: rotate(270deg) translate(0, -142%);
        transform: rotate(270deg) translate(0, -142%);
        -webkit-animation-delay: -0.25s;
        -moz-animation-delay: -0.25s;
        -o-animation-delay: -0.25s;
        animation-delay: -0.25s;
    }

    .tbl-loadindicator-icon .tbl-loadindicator-segment5 {
        -webkit-transform: rotate(225deg) translate(0, -142%);
        -moz-transform: rotate(225deg) translate(0, -142%);
        -ms-transform: rotate(225deg) translate(0, -142%);
        -o-transform: rotate(225deg) translate(0, -142%);
        transform: rotate(225deg) translate(0, -142%);
        -webkit-animation-delay: -0.375s;
        -moz-animation-delay: -0.375s;
        -o-animation-delay: -0.375s;
        animation-delay: -0.375s;
    }

    .tbl-loadindicator-icon .tbl-loadindicator-segment4 {
        -webkit-transform: rotate(180deg) translate(0, -142%);
        -moz-transform: rotate(180deg) translate(0, -142%);
        -ms-transform: rotate(180deg) translate(0, -142%);
        -o-transform: rotate(180deg) translate(0, -142%);
        transform: rotate(180deg) translate(0, -142%);
        -webkit-animation-delay: -0.5s;
        -moz-animation-delay: -0.5s;
        -o-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }

    .tbl-loadindicator-icon .tbl-loadindicator-segment3 {
        -webkit-transform: rotate(135deg) translate(0, -142%);
        -moz-transform: rotate(135deg) translate(0, -142%);
        -ms-transform: rotate(135deg) translate(0, -142%);
        -o-transform: rotate(135deg) translate(0, -142%);
        transform: rotate(135deg) translate(0, -142%);
        -webkit-animation-delay: -0.625s;
        -moz-animation-delay: -0.625s;
        -o-animation-delay: -0.625s;
        animation-delay: -0.625s;
    }

    .tbl-loadindicator-icon .tbl-loadindicator-segment2 {
        -webkit-transform: rotate(90deg) translate(0, -142%);
        -moz-transform: rotate(90deg) translate(0, -142%);
        -ms-transform: rotate(90deg) translate(0, -142%);
        -o-transform: rotate(90deg) translate(0, -142%);
        transform: rotate(90deg) translate(0, -142%);
        -webkit-animation-delay: -0.75s;
        -moz-animation-delay: -0.75s;
        -o-animation-delay: -0.75s;
        animation-delay: -0.75s;
    }

    .tbl-loadindicator-icon .tbl-loadindicator-segment1 {
        -webkit-transform: rotate(45deg) translate(0, -142%);
        -moz-transform: rotate(45deg) translate(0, -142%);
        -ms-transform: rotate(45deg) translate(0, -142%);
        -o-transform: rotate(45deg) translate(0, -142%);
        transform: rotate(45deg) translate(0, -142%);
        -webkit-animation-delay: -0.875s;
        -moz-animation-delay: -0.875s;
        -o-animation-delay: -0.875s;
        animation-delay: -0.875s;
    }

    .tbl-loadindicator-icon .tbl-loadindicator-segment0 {
        -webkit-transform: rotate(0deg) translate(0, -142%);
        -moz-transform: rotate(0deg) translate(0, -142%);
        -ms-transform: rotate(0deg) translate(0, -142%);
        -o-transform: rotate(0deg) translate(0, -142%);
        transform: rotate(0deg) translate(0, -142%);
        -webkit-animation-delay: 0s;
        -moz-animation-delay: 0s;
        -o-animation-delay: 0s;
        animation-delay: 0s;
    }

@-webkit-keyframes tbl-generic-loadindicator-opacity {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.55;
    }
}

@-moz-keyframes tbl-generic-loadindicator-opacity {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.55;
    }
}

@-ms-keyframes tbl-generic-loadindicator-opacity {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.85;
    }
}

@-o-keyframes tbl-generic-loadindicator-opacity {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.55;
    }
}

@keyframes tbl-generic-loadindicator-opacity {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.55;
    }
}