.dashBoxPosition {
    margin: 0%;
    position: absolute;
  }

  .widgetContainer {
    align-items: center;
    height: calc(100% - 2px);
    justify-content: center;
    position: relative;
    background-color: #fff;
    width: calc(100% - 2px);
    border: 1px solid #cfcfcf;
    -webkit-box-shadow: 0px 0px 3px 0px #cfcfcf;
    -moz-box-shadow: 0px 0px 3px 0px #cfcfcf;
    box-shadow: 0px 0px 3px 0px #cfcfcf;
  }

  .widgetContainer-dark {
    align-items: center;
    height: calc(100% - 2px);
    justify-content: center;
    position: relative;
    background-color: rgb(38,38,38);
    width: calc(100% - 2px);
    border: 1px solid black;
    -webkit-box-shadow: 0px 0px 3px 0px black;
    -moz-box-shadow: 0px 0px 3px 0px black;
    box-shadow: 0px 0px 3px 0px black;
    color: rgb(216,216,216);
}

.widgetContainer-dark .button-dark {
  background: black !important;
  border-color: rgb(63,63,63) !important;
}

  .emptyWidget .content {
    color: #abc43a;
    font-size: 25px;
    position: absolute;
    top: calc((100%) / 2);
    text-align: center;
  }

  .content {
    width: 100%;
    position: absolute;
    height: calc(100% - 32px);
    overflow: hidden;
  }

  .header {
    width: 100%;
    height: 32px;
    color: #666666;
    border-bottom: 1px solid #cfcfcf;
    background-color: rgb(252, 252, 252);
}

.header-dark {
  width: 100%;
  height: 32px;
  color: rgb(216,216,216);
  border-bottom: 1px solid black;
  background-color: rgb(15,15,15);
}

.header .title {
  color: #666666;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  height: 30px !important;
  overflow: hidden;
  right: 80px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  left: 7px;
  top: 7px;
}

.header-dark .shortTitle {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  height: 30px !important;
  overflow: hidden;
  right: 30px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  left: 7px;
  top: 7px;
  color: rgb(216,216,216);
}

.header-dark .title {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  height: 30px !important;
  overflow: hidden;
  right: 80px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  left: 7px;
  top: 7px;
  color: rgb(216,216,216);
}

.gear {
  float: right;
  margin: 5px;
  cursor: pointer;
}