.widgetSize {
    float: left;
    width: 47%;
    /* height: 43.6%; */
    height: 270px;
    padding: 10px 10px 10px 10px;
}

   .addPageBox{
        background-color:white;height:100%;width:97%;border: 5px dotted lightgray;
    }

    .addPageBox i{
        color: lightgray;cursor: pointer;font-size: 4em;left: 2%;position: relative;top: 39%;
    }

.mainwrapper{
    background-color:white;height:100%;width:100%
}

.mainwrapper .box {
    /*cursor: pointer;*/
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
    background-color:white;
    height:100%;
}

    .mainwrapper .box img {
        position: absolute;
        left: 0;
        -webkit-transition: all 300ms ease-out;
        -moz-transition: all 300ms ease-out;
        -o-transition: all 300ms ease-out;
        -ms-transition: all 300ms ease-out;
        transition: all 300ms ease-out;
    }

    /* Caption Common Style */
    .mainwrapper .box .caption {
        position: absolute;
        color: #fff;
        z-index: 100;
        -webkit-transition: all 300ms ease-out;
        -moz-transition: all 300ms ease-out;
        -o-transition: all 300ms ease-out;
        -ms-transition: all 300ms ease-out;
        transition: all 300ms ease-out;
        left: 0;
    }

    /** Caption 4: Slide **/
    .mainwrapper .box .slide-caption {
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        text-align: left;
        /* padding: 15px; */
        left: 100%;
    }

    /** Slide Caption :hover Behaviour **/
    .mainwrapper .box .slide-caption {
        -moz-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        opacity: 1;
        transform: translateX(-100%);
    }
    .EditDelete{
        display: none;
    }

    .EditDelete:hover{
        display: block;
    }

 .box-content{
        height:97% !important;
    }

   .box-img{
        /* height: 100%; */
        min-height: 98.6%;
        width: 97.5%; padding:0 5px 5px;
    }

    .mainwrapper .box .bgImage{
        height:102%;left:-20.8px;top: 3px;width: 103.7%;background-color:#C4D6A0
    }

    .mainwrapper .box .openImage{
        cursor:pointer;float: left;left: 27px;position: relative;top: 39.2%;width: 14%;opacity:0;
    }

    .mainwrapper .box .openImgIcon{
        cursor: pointer;position: relative;height: 22px;text-align: left;font-size: 14px;letter-spacing: 0.9px;color: #000000;opacity: 1;left: -11px;font-weight: 600;top: 58%;
    }

    .mainwrapper .box .editImage{
        cursor: pointer;float: right;left: -89px;position: relative;top: 6.8%;width: 11%;opacity:0;
    }

    .mainwrapper .box .editIcon{
        cursor: pointer;position: relative;height: 22px;text-align: left;font-size: 14px;letter-spacing: 0.9px;color: #000000;opacity: 1;left: 49.5%;font-weight: 600;top: 9%;
    }

    .mainwrapper .box .cloneImg{
        cursor: pointer;float: right;left: -58px;position: relative;top: 44.7%;width:10.2%;opacity:0;
    }

    .mainwrapper .box .cloneIcon{
        cursor:pointer;position: relative;height: 22px;text-align: left;font-size: 14px;letter-spacing: 0.9px;color: #000000;opacity: 1;left: 106.5px;font-weight: 600;top: 48%;
    }

    .mainwrapper .box .deleteImg{
        cursor: pointer;float: right;left: -26px;position: relative;top: 82.7%;width:10.2%;opacity:0;
    }

    .mainwrapper .box .deleteIcon{
        cursor:pointer;position: relative;height: 22px;text-align: left;font-size: 14px;letter-spacing: 0.9px;color: #000000;opacity: 1;left: 178px;font-weight: 600;top: 78%;
    }


 .title{
        overflow: hidden; white-space: nowrap;text-overflow: ellipsis;padding:0 7px;
        color: #444;
    }