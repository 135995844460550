.log-in-text {
    position: absolute;
    top: 22px;
    right: 0px;
}

.dropdown:hover .dropdown-menu {
    display: block;
  }

  .dropdown-menu-right {
    right: 0;
    left: auto;
  }


  .dropdown-menu {
    position: absolute;
    top: 90%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  }


  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #abc43a;
    white-space: nowrap;
    text-decoration: none;
  }

  .dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }

  .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    color: #abc43a;
    text-decoration: none;
    background-color: #f5f5f5;
  }


  .dropdown:hover .dropdown-menu {
    display: block;
  }