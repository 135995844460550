
body {
  margin: 0;
    background-color: #e7e9ed !important;
    font-family: Calibri, Segoe, "Segoe UI", Candara, Optima, Arial, sans-serif;
    line-height: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*,
*::before,
*::after {
  box-sizing:content-box !important;
}


