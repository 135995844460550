.page-rotation-menu .dropdown-menu {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    display: none;
    float: left;
    font-size: 14px;
    left: 40px;
    list-style: outside none none;
    min-width: 62px;
    padding: 5px 0;
    position: absolute;
    top: 100%;
    z-index: 1000;
}


.page-rotation-menu .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    line-height: 1.428571429;
    color: #333;
    white-space: nowrap;
    font-size: 14px;
    font-weight: normal !important;
}

.page-rotation-menu .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}

.page-rotation-menu .divider{
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}


