.open-img{
    cursor: pointer;
    float: left;
    left: 36px !important;
    position: relative !important;
    top: 41%;
    width: 14%;
    opacity: 0
}

.open-spn{
    cursor: pointer;position: relative;height: 22px;text-align: left;font-size: 14px;letter-spacing: 0.9px;color: #000000;opacity: 1;left: 0px;font-weight: 600;top: 60%;
}